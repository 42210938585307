@import "../../../styles/variables.scss";

.ticket-fields,
.ui.items.item-group-save-relationship {
  display: flex;
  flex-wrap: wrap;

  .form-actions {
    max-width: inherit;
    width: 100%;
    margin-bottom: 1em !important;
  }

  .form-field {
    flex-grow: 1;
    flex-basis: auto;
    min-width: 230px;
    max-width: 50%;
    padding-right: 1em !important;
    margin: 0 0 1em;

    >.field-text-area,
    >.form-actions {
      max-width: inherit;
      width: 100%;
    }

    @media only screen and (max-width: $tabletLandscapeBreakpoint) {
      width: 100%;
      max-width: inherit;
      min-width: inherit;
    }

    & .form-field-description {
      margin: 0.5rem 0em 0em;
      width: 100%;
      padding: 0em 0em 0em;
      top: 0em;
      left: 0em;
      color: rgba(0, 0, 0, 0.4);
    }
  }

  & .full-width {
    width: 100%;
    max-width: inherit;

    & .form-field {
      width: 100%;
      max-width: inherit;
    }
  }

  & .fields {
    & .form-field {
      padding-right: 0 !important;

      & .field {
        padding-left: 0;
      }
    }

    & .form-field:last-of-type {
      &.field {
        padding-right: 0;
      }
    }
  }

}

.ticket-fields-wrapper {
  margin-right: -1em;
}

.ui.items.item-group-form-fields {
  width: 100%;

  & .form-fields-item {
    display: inline-block;
    width: auto;
  }
}

.ui.form {
  .form-field {
    .disabled.field, .field :disabled {
      opacity: 0.7;
      .ui.disabled.input, .ui.input:not(.disabled) input[disabled] {
        opacity: 1 !important;
      }

    }
    .ui.input:not(.disabled) input[disabled] {
      opacity: 0.7;
    }
  }
}
