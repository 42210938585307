@import "../../../../../styles/variables";

.rag-row {
    // height: 100%;
    display: flex;
    justify-content: space-evenly;  
    align-content: center;
    align-items: center;
    height: 100%;
    gap: 0.5em;

    padding: 0.5em;

    div > span {
        margin-right: 0.5em;
    }

    div > span:last-child {
        margin-right: 0;
    }

    .rag-row--label {
        flex-grow: 1;
    }
   
}