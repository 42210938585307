.theme-visrisk {

  --color-primary: #122D8D;
  --color-primary-active: #2548C7;

  .menu-column-expanded {

    .main-menu {
      &__logo {
        background-image: url("../images/VisRisk_Logo_All_White.svg") !important;
      }
    }
  }



  .logo-login {
    width: 300px;
    height: 60px;
    background-image: url("../images/VisRisk_Logo_Black.svg");
    // background-image: url("../images/Vidende_logo_full_blk.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    margin: 0 auto;
  }


}