@import "../../../styles/variables.scss";

.execution-split-view {
  margin-right: 0 !important;
  .tickets-column {
    padding-right: 0 !important;
    .ticket-list-scroll {
      padding-right: 1em;
    }
  }
  .tickets-column, .ticket-column  {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    .ticket-list-scroll {
      overflow: auto;
      overflow-x: hidden;
    }
  }
  .ticket-column {
    overflow: auto;
  }
  .execution-ticket-set-header {
    margin-top: 1em;
    .main-header {
      margin-bottom: 0.5em;
      display: inline-block;
    }
    .relationship-label > .header {
      display: inline-block;
      margin-left: 0.5em !important;
    }
  }
  &.isSplit {
    margin-right: inherit;
    .tickets-column {

      padding-right: 0 !important;
      border-right: 1px solid $borderColour;
      .ticket-list-scroll {
        padding-right: 0;
      }
    }
    .ticket-column {
      padding: 1em 0 1em 1.5em !important;
      .ticket-page {
        margin-top: -2em;
        padding-right: 1em;
      }
      .close-button {
        position: sticky;
        top: 50%;
        left: -4px;
        z-index: 1;
        cursor: pointer;
        font-size: 1.3em;
        transform: translate(-22px,-50%);
      }
    }
  }

}

.assign-category-button {
  text-decoration: underline;
  cursor: pointer;
}
