.field-manager-activity-viewer {
    // display: flex;
    // flex-direction: row;
    // flex-wrap: wrap;

    // .item {
    //     width: 25%;
    // }
}

.activity-viewer-field {
    display: flex !important;
    flex-direction: row;
    align-items: center;
    > div:first-child {
        flex-grow: 1;
    }
}

.activity-viewer-field.dragging {
    width: 150px !important;
    height: auto !important;
    // border: 1px solid black;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    .action-buttons {
        display: none;
    }
}

.ui.menu .item.activity-list-item {
    padding: 0 0.5em;

    .label {
        margin-top: 0.5em !important;
    }
}