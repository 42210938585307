.search-sort {
  .ui.text.menu {
    display: inline-block;
    margin: 0 0.5em 0 0;
  }

  .search-sort-form{
    display: inline-block;
    margin-left: 1em;
  }
}
