.rich-text-area-wrapper {
  > div {
    transition: height 0.3s ease 0s
  }
  &.error .toastui-editor-defaultUI {
    border-color: #9f3a38;
  }
  &.disabled-mode {
    .tui-editor-contents {
      padding: 16px 25px 16px 25px;
      border: 1px solid #e5e5e5;
      min-height: 100px;
    }
  }
}
