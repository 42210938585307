/*******************************
         Site Overrides
*******************************/

.progress-group {
 .progress, .progress:first-child {
   margin: 0em 0em 0.5em;
   .bar {
     min-width: 0.5em;
   }
 }
 .progress:last-child {
   margin: 0;
 }
}

.ui.progress .bar {
  min-width: 0;
}
