.view-matrix-table {
  display: table;
  table-layout: fixed;
  width: 100%;
  max-width: 100%;

  .view-matrix-table-cell {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
