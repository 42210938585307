 .upload-progress-list {
   .file-name {
     position: relative;
     .progress {
       width: 100%;
       position: absolute;
       top: 0;
       left: 0;
       margin: 0;
       height: 100%;
       z-index: 0;
       opacity: 0.4;
       background: none;
       .bar {
         height: 100%;
       }
     }
   }
 }
