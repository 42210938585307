/*******************************
         Site Overrides
*******************************/

@media only screen and (max-width: @largestMobileScreen) {
  .ui.container {
    width: 100% !important;
  }
}

/* Tablet */
@media only screen and (min-width: @tabletBreakpoint) and (max-width: @largestTabletScreen) {
  .ui.container {
    width: 100%;
  }
}

/* Small Monitor */
@media only screen and (min-width: @computerBreakpoint) and (max-width: @largestSmallMonitor) {
  .ui.container {
    width: 100%;
  }
}

/* Large Monitor */
@media only screen and (min-width: @largeMonitorBreakpoint) {
  .ui.container {
    width: @largeMonitorWidth;
  }
}
