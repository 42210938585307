.execution-page {
  .execution-heading {
    .header {
      display: inline-block;
      margin-right: 1em;
    }

    .execution-filters {
      display: flex !important;
      flex-direction: row !important;
      justify-content: flex-end;
      > form {
        margin-left: 1em;
      }
      > form, > div {
        display: inline-block;
        width: 50%;
      }
    }
  }

  .execution-overview {
    .dashboard-widget.bar {
min-height: 400px;
    }

    .component-kpi .value-component-kpi {
      font-size: 2.5em;
    }
  }
}
