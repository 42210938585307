/*******************************
         Site Overrides
*******************************/

.ui.header {
  margin: 0.5em 0;
  &.inline-block {
    display: inline-block;
    margin-right: 0.5em;
  }
}

.ui.header.attached.inverted {
  background: @purple;
  color: @white;
}

.ui.menu .active.item .ui.header {
  color: unset;
}

.ui.sub.header {
  text-transform: none;
}
