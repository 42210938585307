@import "../../../../../styles/variables";

.rag-dimension {
    // height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
     padding-bottom: 0.5em;
    flex-direction: column;

    &__results {
        display: flex;
        width: 100%;
        justify-content: space-around;
        align-items: flex-end;
    }
    &__result {
        display: flex;
    flex-direction: column;
    align-items: center;
    }

    .ui.header {
        width: 100%;
        text-align: center;
        padding: 0.5em;
    }

    &--exception {
        

        .ui.header {
            background-color: var(--rag-color-red);
            color: $white;
        }
    }
    &--caution {
        

        .ui.header {
            background-color: var(--rag-color-orange);
            color: $white;
        }
    }
    &--positive {
        .ui.header {
            color: $white;
            
        background-color: var(--rag-color-green);
        }
    }
}