

.fair-value-view-party-item {
    // border-left: 1px solid red;

//     background-image: linear-gradient(to right, black 1px, transparent 1px, transparent);
// background-position: left 30px top;
overflow: hidden;
position: relative;

    &::before {
        content: '';
        position: absolute;
        height: 100%;
        width: 2px;
        border-left: 2px solid black;
    left: 8px;
    top: 31px;
    }

    i.circle {
        margin-left: 0px;
    }

    i.plus, i.minus {
        margin-left: 5px;
    }


    &-content__closed {
        .list {
            display: none;
        }
    }

    .header {
        cursor: pointer;
    }

}

.distributor-accordion-title {
    display: flex;
    align-items: center;
    .header {
        flex-grow: 1;
    }
}
.distributor-accordion-content {
    margin-top: -10px !important;
    padding-top: 0 !important;
}
.distributor-progress-bar-container {
    width: 250px;
}
.distributor-right-content {
    display: flex;
    flex-direction: column;
}
.distributor-status-labels {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    .label {
        flex-grow: 1;
        
    text-align: center;
    }

}

.distributor-product-list  {
    > .item {
    display: flex !important;
}
}
