@import "../../styles/variables";

.action-buttons {


  &.hide-mode {
    opacity: 0.5;

    .button {
      display: none !important;
    }

    .button.hide-mode-button,
    .button.save-button {
      display: block !important;
      ;
    }

    &:hover {
      opacity: 1;

      .button.hide-mode-button {
        display: none !important;
      }

      .button {
        display: initial !important;
      }
    }
  }

  &.compact {
    .ui.basic.button {
      padding: 0.6em 0.5em !important;
      box-shadow: none !important;

      &:hover {
        box-shadow: none !important;
      }

    }
  }

  .ui.basic.button {
    &.white {
      .icon {
        color: $white !important;
      }
    }

    &:hover {
      &.white {
        .icon {
          color: $black !important;
        }
      }
    }
  }

  &.ui.buttons .button:only-child {
    padding: 0.6em 0.5em !important;
  }

  .ui.header & .icon:only-child {
    //padding: inherit;
  }

  .ui.header & .icon:only-child {
    margin-right: 0;
  }

}