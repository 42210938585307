@import "../../../styles/variables.scss";


.ticket-row {
  display: flex !important;
  padding: 0 !important;

  width: 100%;

  &:hover {
    .status {
      width: 2em;
      .icons {
        opacity: 1;
      }
    }
  }

  &:last-of-type {
    border-bottom: 1px solid rgba(34, 36, 38, 0.1);
  }

  &.active {
    background: $offWhite;
  }

  .status {
    border-top: 1px solid $white;
    transition: width 0.2s;
    width: 10px;
    margin-top: 0px;
    margin-bottom: 0px;
    padding-top: 0.5em;
    text-align: center;
    .icons {
      opacity: 0;
      transition: opacity 0.3s;
      .ui.icon.button {
        padding: 0;
        margin: 0;
        box-shadow: none;
        background: none !important;
        &:focus, &:active, &:hover {
          background: none !important;
          box-shadow: none;
        }
        &:hover {
           .icon {
             //opacity: 1 !important;
           }
         }
        &.active {
          .icon {
            opacity: 1 !important;
          }
          &:hover {
            .icon {
              //opacity: 0.3 !important;
            }
          }
        }

        .icon {
          margin: 0;
          opacity: 0.3;

        }
      }

    }
    &.with-icon {
      width: 2em;
      .icons {
        opacity: 1;
      }
    }
  }

  .ticket-row-header {
    border-top: 1px solid rgba(34, 36, 38, 0.1);
    cursor: pointer;
    flex: 1;
    padding: 0.7em;

    .header {
      margin-bottom: 0;
    }
    .resolutions {
      display: inline-block;
    }
  }

  .ticket-stats {
    margin-top: 0.5em;
    .label {
      border: none !important;
      background: none !important;
      padding: 0;
      margin-right: 1em;
      > .icon {
        margin-right: 0.2em;
      }
    }
  }

  .actions {
    border-top: 1px solid rgba(34, 36, 38, 0.1);
    width: 160px;
    padding: 0.7em;
    position: relative;
    display: flex;
    align-items: center;
    &.collapsed {
      width: auto;
      flex: none;
    }
  }

  .actions-1,
  .actions-2,
  .actions-3 {
    width: 160px;
    padding: 0.7em;
  }

  &.compact {
    .ticket-row-header {
      flex: 1;
      padding: 0.7em;

      .resolutions {
        display: inline-block;
      }
    }

    .actions {
      width: 170px;
      padding: 0.7em;
    }
  }

  &.placeholder {
    display: table !important;
    height: 62px;
    padding: 16px !important;
  }
}
