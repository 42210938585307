html {
  --color-primary: #531686;
  --color-primary-active: #693296;
}

@import "variables";
@import "print";

$main-menu-width: 65px;
$main-menu-expanded-width: 220px;


$font-path: './assets/fonts/';

@font-face {
  font-family: 'Icons';
  src: url('#{$font-path}icons.eot') format('eot'),
  url('#{$font-path}icons.woff2') format('woff2'),
  url('#{$font-path}icons.woff') format('woff'),
  url('#{$font-path}icons.ttf') format('truetype'),
  url('#{$font-path}icons.svg#mainFont') format('svg');
}


input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type="number"] {
    -moz-appearance: textfield;
}

.column.question-sets {
  width: calc(100% - 360px) !important;
}

.ui.very.close.right.rail.fixed-rail {
  position: fixed;
  overflow-y: scroll;
  left: calc(100% - 330px);
  padding-right: 1em;
  top: 4em;
  height: calc(100% - 4em);
}

.ui.header>.icon.edit-icon {
  font-size: 1em;
}

.programme-categories__column--selected {
  background-color: #f3f4f5;
}

.u-show-on-hover {
  &:hover {
    >.u-show-on-hover__target {
      opacity: 1;
      display: block;
    }
  }

  >.u-show-on-hover__target {
    opacity: 0;
    transition: opacity 0.3s !important;
  }
}

.view-selector {
  position: absolute;
  top: 0;
  right: 1em;
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
  width: 100%;
}

.ui.label.activity-tag {
  margin-bottom: 5px;
  margin-right: 5px;
  margin-left: 0;
}

.activity-filter-row {
  font-size: 1em;

  .dropdown.icon {
    display: none;
  }
}

.ui.table td.collapsing.collapsing-min-width,
.ui.table th.collapsing.collapsing-min-width {
  min-width: 150px;
  white-space: inherit;
}

.public-DraftEditor-content {
  min-height: 110px;
}

.rc-slider-tooltip {
  z-index: 1000000;
}

.gantt_task_line.ticketSet {
  background-color: gray;
  border-color: gray;
}

$main-menu-width: 65px;

.app-container {
  height: 100%;
}

.page-layout {
  &__main-grid {
    height: 100%;
  }

  &__menu-column {
    position: fixed !important;
    height: 100%;
    padding-bottom: 0;
    padding-top: 0 !important;
    top: 0;
    width: $main-menu-width !important;
    padding-right: 0 !important;
    background-color: #f5f5f5;
    border-right: 1px solid #e0e1e2;
  }

  &__content-column {

    padding-left: 0 !important;

    & .ui.container {
      margin-top: 2em;
      margin-bottom: 0em;
      padding-left: 1em;
      padding-right: 1em;
    }
  }
}

.menu-column-expanded {
  width: $main-menu-expanded-width !important;

  .main-menu {
    &__logo {
      background-color: $purple !important;
      padding: 5px 10px !important;
      border-radius: 0 !important;
      background-image: url("../images/Vidende_logo_full_white.png") !important;
      background-size: contain !important;
      margin: 2em 2.8em 0.8em !important;
      background-repeat: no-repeat !important;
      height: 0 !important;
      padding-top: 21% !important;
    }
  }
}

.content-column-menu-expanded {
  margin-left: $main-menu-expanded-width !important;
  width: calc(100% - #{$main-menu-expanded-width}) !important;

  .ui.container {
    @media only screen and (min-width: $tabletBreakpoint) and (max-width: $largeMonitorBreakpoint) {
      width: calc(100% - 15px) !important;
    }

    @media only screen and (max-width: $tabletBreakpoint) {
      width: auto !important;
    }
  }
}



.top-menu {
  border-bottom: 1px solid #e0e1e2 !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  margin-bottom: 0 !important;

  &.ui.secondary.menu .dropdown.item>.menu,
  .ui.text.menu .dropdown.item>.menu {
    //border-radius: .28571429rem;
    margin-top: 0;
  }
}

.login-page {
  //margin-top: 33vh !important;
}

#app {
  height: 100%;
}

.app-container {
  &.--logged-out {
    background: none;
    background-image: url("../images/login_bg.jpg");
    background-size: cover;
    background-position: center center;
    height: 100vh;

    overflow: auto;
  }

  .menu-arrow {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
  }
}

.logo-login {
  width: 300px;
  height: 60px;
  // background-image: url("../images/VisRisk_Logo_Black.svg");
  background-image: url("../images/Vidende_logo_full_blk.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  margin: 0 auto;
}


.google-standalone-search-box {
  margin-bottom: 15px;
}

.ui.vertical.menu .item .menu .item span {
  text-decoration: underline;
  font-size: 14px;
}



.more-info-button {
  text-decoration: underline;
}

.execution-plan-details tr td:first-of-type {
  color: grey;
  padding-left: 0;
}

.header-filter-icon {
  float: right;
}

table tr th .item {
  display: inline;
}

.header-filter-icon {
  position: absolute;
  right: 5px;
  top: 12px;
}

.header-filter-icon.icon {
  top: 15px;
}

#app .page-layout__main-grid {
  .page-layout__content-column {
    padding-bottom: 0;
    margin-bottom: -15px;
    width: 100%;

    & .login-page {
      margin-top: 0;
    }
  }

  .page-layout__menu-column {
    padding-bottom: 0;
    background-color: $purple;

    .item {
      color: $white !important;

      &.active {
        background: rgba(0, 0, 0, 0.15);
      }
    }
  }
}

.pushable {
  overflow: initial;
}

.content-column-menu-hidden {
  margin-left: $main-menu-width;
  width: calc(100% - #{$main-menu-width}) !important;
}

.sidebar.menu {
  max-height: 100vh;
}

.RichTextEditor__root___2QXK- {

  font-family: inherit;

  .rte-field.disabled {
    position: relative;

    &:before {
      position: absolute;
      content: '';
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.04);
    }
  }
}

.ButtonWrap__root___1EO_R {
  z-index: 7 !important;
}

.Dropdown__root___3ALmx select {
  padding: 0.2em 0.5em !important;
}


.body-sidebar {
  overflow-y: scroll;
  height: 94% !important;
  overflow-x: hidden;
}

.footer-sidebar {
  position: absolute !important;
  bottom: 0;
  background: white;
  width: 100%;
}

.modals .red.header {
  margin-top: 0;
}

.body-table-execution-page {
  padding-bottom: 100px !important;

  & .column table:last-of-type :last-child tr:last-of-type .menu {
    bottom: 100%;
    top: auto;
  }
}

.progress-list-party-page .ui.progress {
  position: static;

  & .bar {
    position: static;
  }
}

.form-login,
.form-reset-password {
  .red.header {
    margin-top: 0;
    margin-bottom: 0;
  }

  .error.message {
    display: block !important
  }
}

.checkbox-workflow-activity-set,
.dropdown-workflow-activity-set {
  margin-top: 10px;
}

.remove-workflow-button-action-set {
  margin-left: 10px !important;
  margin-top: 10px !important;
}

.assign-workflow-form {
  margin: 0 !important;
}

.table-cell-fix-wrap {
  white-space: normal;
  overflow: hidden;
  max-height: 1.2em;
  min-width: 50px;
}

//HIGHCHARTS

.highcharts-background {
  fill: $white;
}

.highcharts-credits {
  display: none !important;
}

.highcharts-tooltip span {
  height: auto;
  width: 250px;
  overflow: auto;
  white-space: normal !important;
}

path.highcharts-boxplot-median, path.highcharts-boxplot-stem,
path.highcharts-boxplot-box, path.highcharts-boxplot-whisker {
  stroke: rgba(0, 0, 0, 0.87);
  stroke-width: 1;
}



.vidende-datepicker {
  z-index: 99999 !important;
}


.draggable-component {
  top: auto !important;
  left: auto !important;
}

.readonly {
  opacity: 1 !important;
  & .input {
    opacity: 1 !important;
  }
}
.green-input input[type="text"]{
  background: #6ed457 !important;
}
.red-input input[type="text"]{
  background: #ff1f4e !important;
}
.yellow-input input[type="text"]{
  background: #ffe41d !important;
}
.orange-input input[type="text"]{
  background: #ff7c4e !important;
}
.blue-input input[type="text"]{
  background: #095daa !important;
}