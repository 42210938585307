@import "../../styles/variables.scss";

.vidende-dashboard {
  margin-top: 1em !important;
  margin-bottom: 0em !important;

  &.--editable {
    .dashboard-row {
      min-height: 10em;
    }

    .dashboard-row>.column {
      padding-bottom: 20px !important;
    }

    .dashboard-widget {
      overflow: inherit;
    }
  }

}

.dashboard-action-buttons {
  position: absolute;
  right: 1px;
  bottom: 1px;
  z-index:999;

  .ui.basic.button {
    padding: 0.3em !important;
    background-color: white !important;
  }
}

.dashboard-row {
  min-height: 3em;
  padding: 0 !important;
  padding-left: 1em !important;


  >.column {
    padding: 0 !important;
    margin: 0 !important;
    flex-flow: wrap !important;
    align-content: flex-start;
    &:first-of-type {
      // padding-left: 1em !important;
    }

    >.dashboard-widget:only-child {
      height: calc(100% - 1em);

    }
    >.dashboard-widget {

      padding: 0 !important;
      margin: 0 !important;
      margin-right: 1em !important;
      margin-bottom: 1em !important;

      &.basic {
        margin-bottom: 0 !important;
      }

      &.group {
        display: block;
        padding: 1em !important;

        .header-dashboard-widget {
          padding-top: 0;
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
  }

  &.grid-mode {
    display: grid !important;
    gap: 1em;
    padding-bottom: 1em !important;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(5, 1fr);
    padding-right: 0.9em !important;

    > .column > .dashboard-widget:only-child {
      height: 100% !important;
    }

    > .column {
      width: 100% !important;

      > .dashboard-widget {
        margin-right: 0 !important;
    margin-bottom: 0 !important;
      }
    }
  }
}

.dashboard-widget {
  padding: 0 !important;
  display: inline-flex;
  flex-direction: column;
  border: none !important;
  //box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.1), 0 0px 2px 0 rgba(0, 0, 0, 0.2) !important;
  box-shadow: rgba(0, 0, 0, 0.15) 0 1px 7px !important;
  border-radius: 8px !important;
  // overflow: hidden;
  min-height: 1em;
  width: 100%;

  img {
    max-width: 100%;
  }

  h2.ui.header.header-dashboard-widget {
    font-size: 1.1em;
  }

  &.borderless {
    .ui.header.header-dashboard-widget {
      padding: 0 0 0.5em 0;
      border-bottom: none !important;
    }
  }

  &.isLoading {
    min-height: 5em;
  }

  &.basic {
    box-shadow: none !important;
  }

  &.dropDown,
  &.dateRange,
  &.tabs {
    box-shadow: none !important;
    border-radius: 0 !important;
    overflow: visible;

    &.isLoading {
      min-height: 0;
    }

    .ui.selection.dropdown {
      // border: rgba($purple, 0.3) 1px solid;
    }

  }

  &.dateRange {
    .fields {
      margin-bottom: 0 !important;
    }
  }

  &.extra-padding > div {
    padding: 1em !important;
  }

  &.has-max-height {

    .highcharts-container,
    .component-dashboard-widget.chart-component>div {
      height: 100% !important;
    }
  }

  .header-dashboard-widget {
    -webkit-transition: opacity .3s;
    transition: opacity .3s;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: left;
    padding: 0 25px;
    border: none !important;
    border-bottom: 1px solid $offWhite !important;

    color: $purple !important;
    font-weight: 600 !important;
    background: $white !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    margin-top: 0 !important;
  }

  &.isLoading .component-dashboard-widget,
  &.isLoading .header-dashboard-widget {
    opacity: 0;
  }

  .component-dashboard-widget {
    -webkit-transition: opacity .3s;
    transition: opacity .3s;
    width: 100%;
    height: 100%;
    padding: 0 !important;
    //border-radius: 4px  !important;
    flex-grow: 1;
    display: inline-flex;
    flex-direction: column;
    text-align: center;
    background: $white !important;

    &.chart-component {
      margin: 0;
    }
  }

  &.pie {
    &.has-selection {
      .highcharts-point {
        opacity: 0.3;

        &.selected {
          opacity: 1;
        }
      }
    }
  }



  .dashboard-widget-refresh-button {
    position: absolute;
    right: 0;
    bottom: 0;
    margin: 0;
    opacity: 0.5;
    z-index: 1;
  }

}