.new-version-checkbox {
  margin-right: 38px;
}

.activity-form {
  position: relative;
  margin-bottom: 1em;
  .ui.form .fields:last-child {
    margin-bottom: 0;
  }
}
