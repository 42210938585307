/*******************************
         Site Overrides
*******************************/

.sidebar.menu {
  max-height: 100vh;
}

.ui.wide.right.sidebar.menu.wide{
  width: 50%;
}

.ui.wide.right.sidebar.menu.very.wide{
  width: 70%;
}