/*******************************
         Site Overrides
*******************************/


.ui.grid > .row {
  &.flex-width {
    > .column {
      flex: 1;
    }
  }
}

.ui.grid > .row > .column {
  &.fixed {
    width: 18rem !important;
    flex: none !important;
  }
}
