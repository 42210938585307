
.status-editor-field {
    display: flex;
    margin-bottom: 0;
    margin: 0 !important;
    width: 100% !important;

    .header {
        display: block !important;
        margin-bottom: 0.5em !important;
    }

    .form-field {
        max-width: 100% !important;
    }

    .ticket-fields {
        width: 100% !important;
    }
    & .extra {
        width: auto !important;
        display: flex!important;
    flex-direction: column;
    align-items: flex-end;

    }
}