.json-builder {
  div.jsoneditor-tree div.jsoneditor-tree-inner {
    padding-bottom: 20px;
  }
  .jsoneditor-mode-text  {
    height: 80vh;
  }
  .jsoneditor-text {
    height: 100% !important ;
    max-height: 100% !important; 
  }
}
