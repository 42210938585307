.entity-label  {
  display: inline-block;
  white-space: nowrap;
  margin-bottom: 0.5em;
  &:only-child, &:last-child {
    margin-bottom: 0;
  }

}
.entity-label-placeholder{
    display: inline-block;
    width: 70px;
    margin: 0 0.5em !important;
    height: 1em;
    .line {
      margin: 0;
      margin-bottom: 0;
      height: 0 !important;
    }
}
