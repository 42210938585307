.data-table-component {
  margin: 0 !important;

}

.dashboard-widget.dataTable{
  .dashboard-widget-refresh-button {
    top: 0px;
    bottom: auto;
  }

  &.has-max-height  {
  //margin: 0 !important;
  display: block;
  overflow: auto;
  max-height: 500px;
}}

