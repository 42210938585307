.execution-overview .column .execution-events-segment {
  max-height: 650px;
  margin-top: -1em !important;

  .execution-events-segment-list{
    overflow: hidden;
    overflow-y: auto;
    height: 540px;
  }
}
