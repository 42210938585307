.event-list-item {
  border-top: solid 1px #d9d8d8 !important;

  &:first-of-type {
    border-top: none !important;
  }

  .event-list-item-label {
    font-size: 1.5em;
    margin-top: 0.6em
  }
}
