/*******************************
         Site Overrides
*******************************/

.ui.inverted.button {
  box-shadow: 0px 0px 0px @invertedBorderSize @varPrimary inset !important;
  background: transparent none;
  color: @varPrimary;
  text-shadow: none !important;
}

/* Hover */
.ui.inverted.button:hover {
  background: @varPrimary;
  box-shadow: 0px 0px 0px @invertedBorderSize @white inset !important;
  color: @white;
}
