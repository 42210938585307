.ticket-search-page {
 &--header {
   padding: 1em;
 }
  &--filters {
    //width: 43.75% !important;
    display: flex;
    justify-content: space-between;
    align-content: flex-start;
    margin-bottom: 1em;
    .principal-search {
      max-width: 300px;
    }
    .pagination {
      margin-left: auto;
    }
  }

  .ticket-column {
    border-top: 1px solid rgba(34, 36, 38, 0.1);
    padding-top: 1em;
  }
}
