@import "../../styles/variables.scss";

.execution-plan-activity-card-group {
  display: grid !important;
  grid-template-columns: repeat(auto-fit, minmax(655px, 1fr));
  grid-gap: 1em;
  margin: 1em 0em !important;


  i.tick-icon-card {
    margin-top: -50px;
    background-color: white;
    z-index: 3;
    margin-left: -26px;
  }
  .card-header {
    max-width: calc(100% - 71px);
  }

  .card-in-scope .card-header {
    margin-top: -21px;
  }
  .card {
    width: 100% !important;
    margin: 0 !important;

    .assigned-relationships-list {
      max-height: 200px;
      overflow: auto;
      margin-bottom: 10px !important;
    }

  }
}
