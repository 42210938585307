@import "../../../../../styles/variables";

.rag-header {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 1em;
    .ui.header {
        margin:0;
        // line-height: 1.1em;
    }
    .ui.header:first-child {
        margin-top: 0;
    }
    &--exception {
        background-color: var(--rag-color-red);

        .ui.header {
            color: $white;
        }
    }
    &--positive {
        background-color: var(--rag-color-green);
        .ui.header {
            color: $white;
        }
    }
}