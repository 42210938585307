/*---  Colors  ---*/
$red              : #FC3B64;
$orange           : #F9926E;
$yellow           : #FBE43B;
$olive            : #B5CC18;
$green            : #6ED457;
$teal             : #26BCB1;
$blue             : #136ABA;
$violet           : #AF6EF9;
$purple           : var(--color-primary);
$pink             : #E03997;
$brown            : #A5673F;
$grey             : #767676;
$black            : #1B1C1D;

/*---   Neutrals  ---*/
$fullBlack        : #1A1A1A;
$offWhite         : #ECE9E4;
$darkWhite        : #F3F4F5;
$midWhite         : #DCDDDE;
$white            : #FAFCFC;

$borderColour: #D4D4D5;

/*--- Breakpoints ---*/
$mobileBreakpoint            : 320px;
$tabletBreakpoint            : 768px;
$computerBreakpoint          : 992px;
$tabletLandscapeBreakpoint   : 1024px;
$largeMonitorBreakpoint      : 1200px;
$widescreenMonitorBreakpoint : 1920px;
