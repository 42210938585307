@import "../../../styles/variables.scss";

.ui.form .item-group-save-entity {
  .equal.fields {
    padding-right: 0;
    margin: 0;
    width: 100%;

    & .form-field:first-of-type {
      padding-right: 0.5em;
    }

    & .form-field:nth-child(2n) {
      padding-left: 0.5em;

      .field {
        padding-right: 0;
      }
    }
  }
}
