.add-user-permission-button {
  margin-top: auto !important;

  .button {
    width: 100%;
  }
}

.ui.search.selection.dropdown {
  input {
    width: auto !important;
  }
}


