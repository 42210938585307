@import '../../../styles/variables';

.main-menu {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
  > .item {
    padding-bottom: 5px !important;
    color: $white !important;
  }
  .subitem {
    font-size: 10px;
  }
  &__logo {
    background-color: $purple !important;
    padding: 5px 10px !important;
    border-radius: 0 !important;
    background-image: url('../../../images/Vidende_logo_V_white.png') !important;
    background-size: contain !important;
    margin: 1em 1.1em -1em 1.1em !important;
    background-repeat: no-repeat !important;
    height: 0 !important;
    padding-top: 92.2% !important;
  }
  a.item > i.icon {
    margin: 0 auto 0rem !important;
    margin-bottom: 0 !important;
  }
  .main-menu-sub {
    background-color: transparent !important;
    border: none !important;
    box-shadow: none !important;
    > .item {
      padding-bottom: 5px !important;
      font-size: 0.6em !important;
      background-color: transparent !important;
      line-height: 0.1em !important;
    }
  }
}

.top-menu {
  .item {
    margin: 0 !important;
    i.icon {
      font-size: 1.2em;
    }
  }
}
