.ticket-page {
  .ticket-page-header {
    display: flex;
    .column-1 {
      flex: 1;
      padding-right: 1em;
      margin-bottom: 1em;
    }
    .column-2 {
      width: 170px;
      margin-bottom: 1em;
      .header {
        margin-bottom: 0.1em;
        margin-top: 0.2em;
      }
    }
    &.without-description {
      .column-2 {
        width: 270px;
      }
    }
  }
  .ticket-page-actions {
    display: flex;
    margin-bottom: 1em;
    position: relative;
    .header {
      margin-bottom: 0.1em;
      margin-top: 0.2em;
    }
    .column-1 {
      flex: 1;
    }
    .column-2 {
      width: 260px;
      text-align: right;
    }
  }

  .vidende-dashboard {
    margin-bottom: 0 !important;
  }

  .ticket-page-label {
    max-width: calc(100% - 70px);
  }

  .ticket-breadcrumbs {
    .party-label {
      float: right;
    }
  }

  .ticket-page-breadcrumb {
    max-width: calc(100% - 50px);

    .ui.breadcrumb {
      margin-bottom: 0 !important;
      margin-top: 0 !important;
      padding-bottom: 0 !important;
    }

    .ticket-breadcrumbs {
      display: flex;
      justify-content: space-between;
      padding-top: 0;

      .ui.breadcrumb:first-child {
        margin-top: 1em;
        margin-bottom: 1em;
      }
    }
  }
  .ticket-page-menu {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
}

