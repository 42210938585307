
.queue-status-viewer {
    color: green;

    &-message {
        display: flex;
        > *:first-child {
            flex-grow: 1;
        }
    }
}