/*******************************
         Site Overrides
*******************************/


.ui.menu.wrapping {
  flex-wrap: wrap;
}

.ui.menu.wrapping .item {
  flex-grow: 1;
  min-width: 10%;
  border-bottom: 1px solid @dividerBackground;
}

.ui.menu .menu-arrow {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
}

.menu-column-expanded .ui.vertical.menu .item {
  line-height: 1.71428571em !important;
}

.menu-column-expanded .ui.vertical.menu .item > i.icon{
  float: left !important;
  margin-right: 10px !important;
  font-size: 1.71428571em !important;
}

.menu-column-expanded .ui.vertical.menu .menu-arrow.item > i.icon{
  float: right !important;
}

.menu-column-expanded .ui.vertical.menu .item {
  text-align: left;
}

.menu-column-expanded .ui.vertical.menu .item span{
  margin-left: 10px;
}
.ui.vertical.menu .item .menu .active.item {
  font-weight: inherit;
  background: @activeItemBackground;
}

.ui.vertical.menu .item .menu .active.item.item:hover {
  color: @white;
}

.ui.icon.menu .active.item {
  color: @white;
}
.ui.icon.menu.half-padding .item {
  padding: 0.5em;
}

.ui.icon.menu .item.table-header {
  color: white;
  display: inline;
  padding: 0;
  font-weight: 500;
}

.ui.sidebar.menu .sidebar-pagination .item{
  min-width: 3em;
  text-align: center;
  background: none;
  padding: 0;
  padding-top: 0.92857143em;
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.87);

  &.active {
    color: rgba(0, 0, 0, 0.95);
    background-color: rgba(0, 0, 0, 0.05);
  }

  &::before {
    display: block;
    position: absolute;
    content: '';
    top: 0%;
    right: 0px;
    height: 100%;
    width: 1px;
    background: rgba(34, 36, 38, 0.1);
  }
}

.ui.menu .item {
  > .menu > .dropdown {
    padding: 0;
    border: none;
  }
}

