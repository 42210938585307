/*******************************
         Site Overrides
*******************************/


.ui.form .field.currency .ui.input > input{
  text-align: right;
}

/* On Field(s) */
.ui.form .fields.error .field label,
.ui.form .field.error label,
.ui.form .fields.error .field .input,
.ui.form .field.error .input {
  color: @formErrorColor !important;
}
