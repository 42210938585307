@import "../../../../../styles/variables.scss";

.component-kpi {
  flex-grow: 1;
  display: inline-flex;
  flex-direction: column;
  text-align: center;
  
  .value-component-kpi  .ui.header{
    font-weight: bold;
  }
  .value-component-kpi {
    font-size: 26px;
  }
  .value-component-kpi {
    font-size: 40px;

  }

  .label-component-kpi .ui.header, .value-component-kpi .ui.header {
    margin: 0;
    color: $purple;
}



  &--exception .highlight-bar{
    background-color: var(--rag-color-red);
  }
  &--exception.component-kpi--full-highlight .content-component-kpi{
    background-color: var(--rag-color-red);
    .label-component-kpi .ui.header, .value-component-kpi .ui.header {
        color: $white;
    }
}

&--positive .highlight-bar{
  background-color: var(--rag-color-green);
}
&--positive.component-kpi--full-highlight .content-component-kpi{
    background-color: var(--rag-color-green);
    .label-component-kpi .ui.header, .value-component-kpi .ui.header {
    
      color: $white;
  } 
}

&--caution .highlight-bar{
  background-color: var(--rag-color-orange);
}
  
&--caution.component-kpi--full-highlight .content-component-kpi{
    background-color: var(--rag-color-orange);
    .label-component-kpi .ui.header, .value-component-kpi .ui.header {
      color: $white;
  }
    
}


&--none .content-component-kpi{ 
    background-color: transparent;
   
    .label-component-kpi .ui.header, .value-component-kpi .ui.header {
      color: $white;
  }
    
}

  &.second {
    .value-component-kpi {
      margin-top: 90px;
      font-size: 100px;
      line-height: 80px;
      max-width: 100%;
    }

    .label-component-kpi {
      margin-top: 58px;
      margin-bottom: 89px;
      font-size: 32px;
      font-weight: 900;
      max-width: 100%;
      line-height: 28px;
    }

    .button {
      margin-top: 37px;
    }
  }

  &.third {
    .label-component-kpi {
      font-size: 16px;
      margin-top: 6px;
    }

    .value-component-kpi {
      font-size: 72px;
    }

    .icon-component-kpi {
      font-size: 18px;
    }

    .icon-wrapper-component-kpi {
      margin-top: 7px;
      margin-left: 27px;
    }
  }

  .content-component-kpi {
    display: flex;
    flex-grow: 1;
  }

  .highlight-bar {
    position: absolute;
    width: 10px;
    height: 100%;
    margin-left: 0px;
    border-radius: 4px 0 0 4px;
    top: 0;
  }

  .values-component-kpi {
    width: 100%;
    flex-grow: 1;
    // margin-left: -65px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    // padding-right: 1em;
    padding: 0 10px;
  }

  .icon-component-kpi {
    display: inline-block;
    // font-size: 40px;
    // line-height: 40px;
  }

  .label-component-kpi {
    // font-size: 20px;
    margin-top: 10px;
    font-weight: 600;
    color: $purple;
  }

  .value-component-kpi {
    // margin-left: 5px;
    // font-size: 3.5em;
    color: $purple;
    margin-top: 8px;
    font-weight: 700;
    line-height: 78px;
  }

  .icon-wrapper-component-kpi {
    display: flex;
    align-items: center;
    line-height: 100%;
    justify-content: center;
    margin-left: 25px;
  }
}
