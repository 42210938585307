.executions-page .execution-list {
  padding-bottom: 0 !important;
}

.execution-list-scroll {
  overflow: auto;
  overflow-x: hidden;
}

.pagination-grid {
  width: 100%;
}