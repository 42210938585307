.context-menu {
  position: fixed !important;
  left: 100px;
  top: 100px;
  z-index: 1;
  &.ui.popup {
    padding: 0;
    box-shadow: 0;
    border: none;
  }
}
