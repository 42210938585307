.party-relationship-info {
  > .header > .label {
    float: right;
  }
  .fields {
    display: flex;
    .header {
      margin-bottom: 0;
    }
  }
  .party-relationship-info {
    margin-bottom: 1em;
  }
}
