.field.field-text-area {
  textarea {
    width: 100%;
  }
}
.ui.form .field-text-area {
  .field.disabled {
    textarea {
      pointer-events: auto;
    }
  }
}
