/*******************************
         Site Overrides
*******************************/

code {
  background: #E0E1E2;
  border: @black dashed 1px;
}

body {
  background: @white;
}

html,
body {
  height: 99.9%;



}
