@import "../../styles/variables";

.binder-matrix {
  tr {
    td.matrix-header {
      padding: 0.5em 0em 0.5em 0.7em !important;

      > div {
        display: flex;

        .header {
          flex-grow: 1;
          margin-bottom: 0;
        }

        .buttons {
          display: block;

          .button {
            color: $black !important;
            border: none;

            &:hover, &:active, &:focus {
              border: none !important;
              box-shadow: none !important;
              color: $black !important;
            }
          }
        }
      }
    }
  }
}
