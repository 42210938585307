.metric-result-viewer {

height: 100%;
    &--accordion-title {
        display: flex;
    justify-content: flex-start;
    align-items: center;
    }

    &--accordion-title-result {
        margin-left: auto !important;
        i {
            margin-left: 0.5em !important;
        }
    }
}