.recent-attachments-segment .attachments-list {
  margin-top: 0 !important;
}

.search-attachments-segment {
  .attachments-list {
    margin: 0;
  }
}

.ui.segment.upload-attachments-segment {
  margin-bottom: 0 !important;

  .placeholder {
    border-style: dashed;
    border-width: 3px;
  }

}

.recent-attachments-segment,
.search-attachments-segment {
  height: calc(18rem + 2em + 2px);
  overflow: auto;

  &.editing-mode {
    height: 32rem;
  }
}

.upload-attachments-progress-list,
.search-attachments-segment {
  min-height: calc(18rem + 2em + 2px);
}

.full-width {
  width: 100%;
}
