@media print {

  .ui.fluid.container {
    padding: 0 !important;
    margin: 0 !important;
  }

.page-layout__content-column, .content-column-menu-hidden,
.page-layout__content-column.content-column-menu-hidden
{
  position: fixed;
  height: 0 !important;
  width: 100% !important;
  margin: 1em !important;
  width: 100% !important;
  padding: 0 !important;
}
  .top-menu {
    display: none !important;
  }


  .hide-for-print {
    display: none !important;

  }

  .vidende-dashboard {
    top: 0 !important;
    left: 0 !important;
    width: 100% !important;
    z-index: 9999 !important;
    background: white !important;
    padding: 0 !important;
    margin-right: -2rem !important;
    position: relative;
    display: block !important;
  }

  .pushable {
    height: initial !important;
  }

  * {

    //background: white !important;
    box-shadow: none !important;
  }

  .dashboard-widget {
    //box-shadow: none !important;
    //border-radius: 0 !important;
    page-break-inside:avoid;
    break-inside: avoid;
  }

  #app {

    //margin-left: 1.5em;
    margin-right: -1.5em;

    .page-layout__main-grid, .page-layout__content-column {
      display: block;
      height: auto !important;
    }

    .app-container {
      //height: 100%;
      padding-left: 0.5em;
      padding-right: 2em;
    }

    .content-column-menu-expanded {
      margin-left: 1em !important;
      width: 100% !important;
    }
    .app-container .ui.container {
      padding-left: 0;
      padding-right: 0;
    }
    .page-break {
      page-break-before: always;
      break-before:page;
      height: 0;
      overflow: hidden;
      display: block;  // optional. must be sure it is block item in document flow
      clear: both;   // optional. use only if you are using float
    }
    table { page-break-inside:auto }
    tr    { page-break-inside:avoid; page-break-after:auto }
  }

}
