$pro-blue: #48a9c2;

.pro-cover {
  margin: 1em;
  h1 {
    color: $pro-blue;
    margin-top: 1em !important;
  }
  h3 {
    margin-bottom: 1em !important;
  }
}
.pro-report-control {
 * {
   margin: 0;
 }

  .pro-report-control-activity, .pro-report-control-activity-set, h2, h4, p {
    margin-bottom: 0.5em;
  }
  h2 {
    margin-top: 1em;
  }

  .pro-blue, h2, h3 {
    color: $pro-blue;
  }

  .pro-report-control-activity > div {
    border: 1px dotted $pro-blue;
    padding: 0.5em;
    margin-bottom: 0.5em;
  }


}

.pro-report-contents {
  .pro-blue, h2, h3 {
    color: $pro-blue;
  }
  h3 {
    margin-bottom: 0;
  }
}

.pro-exec-summary {
  h2 {
    color: $pro-blue;
  }
  .ui.table {
    border: 1px solid $pro-blue;
  }
  .ui.table tr td {
    border-top: 1px solid $pro-blue;
    padding: 0.5em;
  }

  .ui.celled.table tr th, .ui.celled.table tr td {
    border-left: 1px solid $pro-blue;
  }
}


.pro-report-reccs {
  h2 {
    color: $pro-blue;
  }
  .ui.table thead th {
    background: $pro-blue;
    color: rgba(0, 0, 0, 0.87);
  }
}
