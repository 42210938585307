.relationship-label {
  margin: 0.5em 0;
  display: flex;
  align-items: center;
  .header {
    margin: 0em 0 !important;
    display: flex !important;
    align-items: center;
    flex-grow: 1;
    .label {
      margin-left: 0.5em;
    }
    .icon {
      display: inline-block;
    }
  }
  //.label {
  //  margin: 0.3em 0.5em 0.3em 0 !important;
  //}

}
