@import "../../styles/variables.scss";

.assign-all-button {
  text-decoration: underline;
  cursor: pointer;
}

.ticket-set-header {
  flex-direction: column;
    align-items: flex-start !important;
  .ui.header {
    margin-bottom: 0;
    margin-right: 0.5em;
    flex-grow: 1;
  }
  .ticket-set-header-entity-labels {
    display: flex;
    gap: 0.5em;
  }

  margin-bottom: 0.5em !important;
  flex-grow: 1 !important;
}

.transition-buttons {
  .button {
    margin-bottom: 0.5em !important;
  }

  .ui.popup {
    background-color: $purple;
    will-change: unset !important;
    transform: none !important;
    top: 55px !important;
    right: 2px !important;
    left: auto !important;
    text-align: center;
    color: $white;

    &:before {
      background: $purple !important;
    }

    :before {
      top: -5px;
      right: 8em;
    }

    &.doubledTop {
      top: 100px !important;
    }
  }


}
